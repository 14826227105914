<template>
  <div class="address-page">
    <headerBar :title="i18n.t('bind.bindCardTitle')"  :showArrow="true" @back="back"></headerBar>

    <div class="address-form">
      <div class="form-item">
        <div class="label">{{i18n.t('bind.Company')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.Company" :placeholder="i18n.t('bind.placeholder4')">
<!--          <input type="text" :readonly="readonly" v-model="form.Company" placeholder="IBAN">-->
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('bind.RealName')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.RealName" :placeholder="i18n.t('bind.placeholder5')">
        </div>
      </div>
      <div class="form-item" >
<!--        <div class="label">{{i18n.t('bind.Card')}}</div>-->
        <div class="label">IBAN</div>
        <div class="input ">
<!--          <input type="text" :readonly="readonly" v-model="form.Card" :placeholder="i18n.t('bind.placeholder6')">-->
          <input type="text" :readonly="readonly" v-model="form.Card" placeholder="IT45X03395129000XXXXXXXXXX0">
        </div>
      </div>
      <div class="form-item" >
        <div class="label label-self">{{i18n.t('bind.Type')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.Type" :placeholder="i18n.t('bind.placeholder9')">
        </div>
      </div>
      <div class="form-item" >
        <div class="label label-self" >{{i18n.t('bind.TaxID')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.TaxID" :placeholder="i18n.t('bind.placeholder8')">
        </div>
      </div>
      <div class="form-item" v-show="readonly === false">
        <div class="label">{{i18n.t('bind.label2')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.password" :placeholder="i18n.t('bind.placeholder2')">
        </div>
      </div>
    </div>


    <div class="btn" v-show="readonly === false">
      <Button @click="submit">{{i18n.t('address.btn')}}</Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import {addBank, addCard, addUsdt, doWithdraw, getBankList, getUsdtList} from "@/api/user";
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      form: {
        Company: '',
        RealName: '',
        TaxID: '',
        Card: '',
        Type: '',
        password: '',
      },
      readonly:true,
      userMoney: '0.00',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
      init(){
          getBankList().then(res =>{
              if (res.code === 0) {
                  console.log(res.data.Items)
                  if(res.data.Items.length === 0) {
                      this.readonly = false
                  } else {
                      this.form = res.data.Items[0]
                  }
              }
          })
      },
    back() {
      this.$router.push({
        name: "mine"
      })
    },
    setMoney(money) {
      this.form.number =  money
    },
    submit() {
        let form = new FormData()
        form.append('Company', this.form.Company)
        form.append('RealName', this.form.RealName)
        form.append('Card', this.form.Card)
        form.append('TaxID', this.form.TaxID)
        form.append('Type', this.form.Type)
        form.append('password', this.form.password)
      addBank(form).then(res => {
            if (res.code == 0) {
              this.back()
                this.$toast(res.msg)
            } else {
                this.$toast(res.msg)
            }
        })
    },
    setAll() {
      this.form.money = this.userMoney
    }
  }
}
</script>
<style scoped>
.label-self::before{
  content: none !important; /* 或 content: ""; */
}
</style>